import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';

@customElement('northell-footer')
export class NorthellFooter extends LitElement {
    static override styles = css`
        footer {
          font-size: 10px;
          line-height: 14px;
          text-align: right;
          color: #a5acbd;
          background-color: #f9f9f9;
          opacity: 80%;
          height: 14px;
          margin-top: auto;
          padding: 5px;
        }
    `;

    override render() {
        return html`
            <footer>
                <div class="container">
                    Copyright 2004 - 2023 Northell Partners Ltd. All Rights Reserved.
                </div>
            </div>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'northell-footer': NorthellFooter;
    }
}
